<template>
    <div 
        class="item-list mr-2" 
        :class="!isMobile && hide ? 'hide_item': ''">
        <div class="py-2 mx-2 px-3 flex justify-between items-center  item-title">
            <div class="font-semibold flex items-center title-badge ">
                <div class="flex items-center">
                    <a-badge :color="column.color" />
                    <span> {{ column.name }}</span>
                </div>
                <span class="text-gray ml-2 count">
                    <template v-if="loading && page === 1">
                        <a-spin size="small" />
                    </template>
                    <template v-else>
                        {{ count }}
                    </template>
                </span>
            </div>
            <template v-if="!isMobile">
                <a-button
                    @click="hideBlock()"
                    class="block_btn text_current ant-btn-icon-only"
                    size="small" 
                    type="link">
                    <i class="fi fi-rr-caret-square-left_1"></i>
                </a-button>
            </template>
        </div>
        <div 
            size="small" 
            class="wrapper-item">
            <div 
                class="p-2" 
                v-if="column.loading && column.page === 1">
                <a-skeleton 
                    active 
                    :paragraph="{ rows: 4 }" />
            </div>
            <div 
                v-if="isMobile || !hide" 
                class="scroll_wrap">
                <draggable
                    v-bind="dragOptions"
                    class="list-group"
                    :id="column.name"
                    :forceFallback="true"
                    :list="list"
                    :group="taskType"
                    @end="end"
                    draggable=".active_task"
                    @change="change">
                    <CardKanban
                        @filter="filterItem"
                        v-for="(element) in list"
                        :key="element.id"
                        :item="element" />
                    <infinite-loading
                        :distance="70"
                        :identifier="column.name"
                        :ref="`infinite_${column.code}`"
                        @infinite="infiniteHandler">
                        <div 
                            slot="spinner" 
                            class="pt-1">
                            <a-spin v-if="list && list.length" />
                        </div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import draggable from "vuedraggable"
// import CardKanban from './CardTypes/CardKanban'
import CardKanban from './Item'
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
export default {
    components: {
        draggable,
        InfiniteLoading,
        CardKanban
    },
    props: {
        column: {
            type: Object,
            required: true
        },
        queryParams: {
            type: Object,
            default: () => null
        },
        selectElement: {
            type: Object,
            default: () => null
        },
        setSelectElement: {
            type: Function,
            default: () => {}
        },
        implementType: {
            type: String,
            default: ''
        },
        implementId: {
            type: [String, Number],
            default: null
        },
        taskType: {
            type: String,
            default: 'task'
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            isMobile: state => state.isMobile
        }),
        filters() {
            if(this.implementId)
                return {
                    [this.implementType]: this.implementId
                }
            else
                return null
        }
    },
    data() {
        return {
            pageSize: 15,
            pageModel: `page_kanban_${this.taskType}_tasks.TaskModel`,
            dragOptions: {
                animation: 200,
                ghostClass: "ghost"
            },
            page: 1,
            loading: false,
            next: true,
            list: [],
            count: 0,
            hide: localStorage.getItem(`hide_col_${this.column.code}`) ? localStorage.getItem(`hide_col_${this.column.code}`) : false,
            listCount: 0
        }
    },
    created() {
        if(this.hide)
            this.infiniteHandler()
    },
    sockets: {
        task_update({data}) {
            if(data) {
                if(this.list.length) {
                    const index = this.list.findIndex(f => f.id === data.id)
                    if(index !== -1) {
                        const find = this.list.find(f => f.id === data.id)

                        let can_update_status = false

                        if(this.user?.id === data.author.id || this.user?.id === data.operator.id) {
                            can_update_status = true
                        }

                        this.$set(this.list, index, {
                            ...find,
                            ...data,
                            can_update_status
                        })
                    }
                }
            }
        }
    },
    methods: {
        checkReload() {
            if(this.listCount < this.count && this.next) {
                this.reload(true)
            }
        },
        reload(last = false) {
            if(this.hide) {
                this.page = 1
                this.count = 0
                this.listCount = 0
                
                this.next = true
                this.list = []
                this.setSelectElement(null)
                this.infiniteHandler()
            } else {
                if(last) {
                    this.page = this.page - 1
                    this.latUpdateHandler()
                } else {
                    this.page = 1
                    this.count = 0
                    this.listCount = 0
                    
                    this.next = true
                    this.list = []
                    this.setSelectElement(null)

                    this.$nextTick(() => {
                        this.$refs[`infinite_${this.column.code}`].stateChanger.reset()
                    })
                }
            }
        },
        hideBlock() {
            this.hide = !this.hide

            if(this.hide)
                localStorage.setItem(`hide_col_${this.column.code}`, true)
            else
                localStorage.removeItem(`hide_col_${this.column.code}`)
        },
        end(e) {
            // console.log(e, 'el')
        },
        changeListCount() {
            this.count += 1
            this.listCount += 1
            if(this.count >= this.pageSize && this.list.length < this.count)
                this.list.splice(this.list.length - 1, 1)
        },
        async change(e) {
            try{
                let element = null

                if(e.added) {
                    element = e.added.element

                    try {
                        const index = this.list.findIndex(f => f.id === element.id)

                        let previous = null,
                            next = null;

                        if(index !== -1) {
                            next = this.list[index - 1] ? this.list[index - 1].id : null
                            previous = this.list[index + 1] ? this.list[index + 1].id : null
                        }

                        await this.$http.put(`/tasks/task_kanban/${element.id}/status/`, { 
                            current: element.id,
                            status: this.column.code,
                            previous,
                            next
                        })
                        this.changeListCount()
                        eventBus.$emit(`RELOAD_COLUMN_${element.status.code}`)
                    } catch(e) {
                        console.log(e)
                    }
                }
                if(e.removed) {
                    if(this.count > 0) {
                        this.listCount -= 1
                        this.count -= 1
                    }
                }
                if(e.moved) {
                    element = e.moved.element
                    try {
                        const index = this.list.findIndex(f => f.id === element.id)

                        let previous = null,
                            next = null;

                        if(index !== -1) {
                            next = this.list[index - 1] ? this.list[index - 1].id : null
                            previous = this.list[index + 1] ? this.list[index + 1].id : null
                        }

                        await this.$http.put(`/tasks/task_kanban/${element.id}/status/`, { 
                            current: element.id,
                            status: this.column.code,
                            previous,
                            next
                        })
                    } catch(e) {
                        console.log(e)
                    }
                }
            } catch(error) {
                console.log(error)
            }
        },
        filterItem(data){
            const query = Object.assign(this.$route.query, data)

            this.$router.replace({name: "kanban"})
            this.$router.push({name: "kanban", query: query})
        },
        async latUpdateHandler() {
            try {
                const idx = 0
                this.loading = true
                let params = this.$route.query
                params['filters'] = {status: this.column.code}
                params['page_name'] = this.queryParams?.page_name ? this.queryParams.page_name : this.pageModel
                params['page'] = this.page
                params['page_size'] = this.pageSize
                params['task_type'] = this.taskType

                if(this.filters) {
                    params['filters'] = {
                        ...params['filters'],
                        ...this.filters
                    }

                    if(this.queryParams?.filters) {
                        params['filters'] = {
                            ...params['filters'],
                            ...this.queryParams.filters
                        }
                    }
                }

                const res =  await this.$http('tasks/task_kanban/list/', {params} )

                res.data.results.forEach(item => {
                    const find = this.list.find(f => f.id === item.id)
                    if(!find) {
                        this.list.push(item)
                    }
                })

                this.count = res.data.count
                this.listCount = this.list.length

                if(res.data.next) {
                    this.page += 1
                    this.next = true
                } else {
                    this.next = false
                }
            } catch(error){
                this.$message.error(this.$t('task.error') + error)
            } finally {
                this.loading = false
            }
        },
        async infiniteHandler($state = null) {
            if(this.next) {
                if(!this.loading) {
                    try {
                        const idx = 0
                        this.loading = true
                        let params = this.$route.query
                        params['filters'] = {status: this.column.code}
                        params['page_name'] = this.queryParams?.page_name ? this.queryParams.page_name : this.pageModel
                        params['page'] = this.page
                        params['page_size'] = this.pageSize
                        params['task_type'] = this.taskType

                        if(this.filters) {
                            params['filters'] = {
                                ...params['filters'],
                                ...this.filters
                            }

                            if(this.queryParams?.filters) {
                                params['filters'] = {
                                    ...params['filters'],
                                    ...this.queryParams.filters
                                }
                            }
                        }

                        const res =  await this.$http('tasks/task_kanban/list/', {params} )
                        this.list = this.list.concat(res.data.results)
                        this.count = res.data.count
                        this.listCount = this.list.length

                        if(res.data.next) {
                            this.page += 1
                            this.next = true
                            if($state)
                                $state.loaded()
                            
                        } else {
                            this.next = false
                            if($state)
                                $state.complete()
                        }
                    } catch(error){
                        this.$message.error(this.$t('task.error') + error)
                    } finally {
                        this.loading = false
                    }
                }
            } else
            if($state)
                $state.complete()
        },
        deleteColumnTask(task) {
            if(this.list.length) {
                const index = this.list.findIndex(f => f.id === task.id)
                if(index !== -1) {
                    this.$delete(this.list, index)
                    if(this.count > 0)
                        this.count -= 1

                    if(!this.list.length && this.count)
                        this.$nextTick(() => {
                            this.$refs[`infinite_${this.column.code}`].stateChanger.reset()
                        })
                }
            }
        }
    },
    mounted() {
        eventBus.$on(`RELOAD_COLUMN_${this.column.code}`, () => {
            this.checkReload()
        })

        eventBus.$on('STATUS_TASK_KANBAN', ({task, status}) => {
            this.deleteColumnTask(task)

            if(this.column.code === status.code) {
                this.list.unshift(task)
                this.changeListCount()
            }
        })
        eventBus.$on(`update_filter_tasks.TaskModel`, () => {
            this.reload()
        })
        eventBus.$on('DELETE_TASK_KANBAN', data => {
            this.deleteColumnTask(data)
        })
        eventBus.$on('UPDATE_TASK_KANBAN', data => {
            if(this.list?.length) {
                const index = this.list.findIndex(f => f.id === data.id)
                if(index !== -1) {
                    this.$set(this.list, index, data)
                }
            }
        })
        eventBus.$on('ADD_TASK_KANBAN', data => {
            if(this.column.is_open) {
                this.list.unshift(data)
                this.changeListCount()
            }
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_filter_tasks.TaskModel`)
        eventBus.$off(`DELETE_TASK_KANBAN`)
        eventBus.$off(`UPDATE_TASK_KANBAN`)
        eventBus.$off(`ADD_TASK_KANBAN`)
        eventBus.$off('STATUS_TASK_KANBAN')
        eventBus.$off(`RELOAD_COLUMN_${this.column.code}`)
    }
}
</script>

<style lang="scss">
.list-group{
    min-height: 50%;
}
</style>

<style lang="scss" scoped>
.scroll_wrap{
    padding-left: 7px;
    padding-right: 7px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.wrapper-item{
    height: calc(100% - 40px);
}
.item-list{
    min-width: 340px;
    max-width: 340px;
    height: 100%;
    scroll-snap-align: start;
    flex-grow: 0;
    background-color: #eff2f5;
    border-radius: var(--borderRadius);
    flex-shrink: 0;
    padding-bottom: 5px;
    overflow: hidden;
    .block_btn{
        margin-right: -7px;
    }
}
</style>